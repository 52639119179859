<template>
  <div class="vip-w-container a1">
    <div class="vip-wrapper p-relative" :class="theme">
      <img v-if="theme == 'theme1'" class="vip-close-btn" @click="closeBtnClick"
        src="../assets/theme1/vip-close-btn.png" />
      <img v-else class="vip-close-btn" @click="closeBtnClick" src="../assets/vip-close-btn.png" />
      <div class="flex-center vip-title p-relative b-de" :class="theme">
        详情
      </div>

      <div class="unlock-row flex p-relative unlocked">
        <div class="filed-title">
          待领取:<span :class="theme">{{ can_claim }}</span>
        </div>
        <div class="receive-button a1" @click="claimApi" :class="{ theme1: theme == 'theme1', disabled:bonusDetailsDisabled}">
        </div>
      </div>

      <div class="unlock-row flex rule">
        <div class="filed-title">
          总金额:<span :class="theme">{{ total_amount }}</span>
        </div>
        <div class="unlock-rule" :class="{ theme1: theme == 'theme1' }" @click="unlockRuleShow">
          如何解锁?
        </div>
      </div>

      <div class="record-table" :class="theme">
        <div class="record-row head">
          <div>
            时间
          </div>
          <div>
            充值金额
          </div>
          <div>
            奖励金额
          </div>

        </div>
        <div class="record-l-scroll" :class="theme">
          <div class="record-row" v-for="bonus of bonusList">
            <div>
              {{ bonus.created_at }}
            </div>
            <div>
              {{ bonus.recharge_amount }}
            </div>
            <div>
              {{ bonus.total }}
            </div>
            <!--
            <div>
              {{bonus.expire_at}}
            </div>
            -->
          </div>
        </div>

      </div>
    </div>

    <div class="rule-wrapper flex" @click="unlockRuleClose" v-show="showUnlockRule">
      <div class="rule-content p-relative" :class="theme" @click.stop="">
        <img v-if="theme == 'theme1'" class="vip-close-btn" @click="closeUnlockDesBtnClick" src="../assets/theme1/vip-close-btn.png" />
        <img v-else class="vip-close-btn" @click="closeUnlockDesBtnClick" src="../assets/vip-close-btn.png" />
        <div v-html="howToUnlockHtml">

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      theme: '',
      unlock_rate: 60,
      bet_amount: 10,
      bonusDetailsDisabled:1,
      bonusList: [],
      showUnlockRule: 0,
      can_claim: 0,
      total_amount: 0,
      howToUnlockHtml:'',
    }
  },
  mounted() {
    this.theme = globalConfig.theme;

    let data = localStorage.getItem('act_recharge_data');
    data = JSON.parse(data);
    let username = localStorage.getItem('username');
    this.getData(username);

    this.can_claim = 1 * data.can_claim;
    this.bonusDetailsDisabled = this.can_claim >= 5 ? 0 : 1;
    
    this.total_amount = data.total_amount;
    this.bet_amount = Math.round(data.total_amount / this.unlock_rate * 100000) / 100;

  },
  methods: {
    claimApi() {
      if(this.bonusDetailsDisabled) return;
      let userName = localStorage.getItem('username');

      axios.post(globalConfig.api_domain + '/act_recharge/claim?username=' + userName).then(response => {
        localAlert(response.data.message);
        if(response.data.code == 200){
          setTimeout(() => {
            window.globalConfig.showPanelByKey('')
            window.globalConfig.updateHomeData();
          }, 500)
        }
      }).catch(error => {
        console.error("There was an error fetching the data", error);
      });
    },
    closeUnlockDesBtnClick(){
      this.showUnlockRule = 0;
    },
    closeBtnClick() {
      window.globalConfig.showPanelByKey('recharge-act')
    },
    unlockRuleClose() {
      this.showUnlockRule = 0;
    },
    unlockRuleShow() {
      this.showUnlockRule = 1;
      this.howToUnlock();
    },
    getData(username) {
      axios.get(globalConfig.api_domain + '/act_recharge/bonus_list?username=' + username).then(response => {
        if (parseInt(response.data.code) === 1) {
          localAlert(response.data.message);
        } else {
          this.bonusList = response.data.data.data;
        }
      })
      .catch(error => {
        console.error("There was an error fetching the data", error);
      });
      return;

      this.bonusList = [
        {
          created_at: '2023-09-23 10:35:00',
          recharge_amount: 1,
          total: 12,
        },
        {
          created_at: '2023-09-23 10:35:00',
          recharge_amount: 1,
          total: 12,
        },
        {
          created_at: '2023-09-23 10:35:00',
          recharge_amount: 1,
          total: 12,
        },
        {
          created_at: '2023-09-23 10:35:00',
          recharge_amount: 1,
          total: 12,
        },
        {
          created_at: '2023-09-23 10:35:00',
          recharge_amount: 1,
          total: 12,
        },
        {
          created_at: '2023-09-23 10:35:00',
          recharge_amount: 1,
          total: 12,
        },
        {
          created_at: '2023-09-23 10:35:00',
          recharge_amount: 1,
          total: 12,
        }
      ];

    },
    async getActiveRechargeData(){
      let userName = localStorage.getItem('username');
      if(!userName) return;
      await axios.get(`${globalConfig.api_domain}/act_recharge?username=${userName}`).then(response => {
        if (parseInt(response.data.code) === 1) {
          alert(response.data.message);
        } else {
          localStorage.setItem('act_recharge_data', JSON.stringify(response.data.data));
        }
      }).catch(error => {
        console.error("There was an error fetching the data", error);
      });
    },
    async getLocalBonusData() {
      let detail_unlock = '';
      await this.getActiveRechargeData();

      let data = localStorage.getItem('act_recharge_data');
      if (data) {
        data = JSON.parse(data);
        detail_unlock = data.detail_unlock;
      }

      return detail_unlock;
    },
    async howToUnlock() {
      this.howToUnlockHtml = await this.getLocalBonusData();
    }
  }
}
</script>

<style lang="stylus">
.rule-content
  & > div
    dl
      font-size .14rem !important
    dt
      font-size .16rem !important
</style>
<style lang="stylus" scoped>
.vip-w-container
  width 100%
  height 100%
  position fixed
  z-index 2
  top 0
  left 0
  background-color rgba(0,0,0,.4)
  .rule-wrapper
    width 100%
    height 100%
    position absolute
    top 0
    left 0
    background-color rgba(0,0,0,.4)
    z-index 4
    .rule-content
      width 3.78rem
      height 3.08rem
      margin auto;
      padding .2rem .12rem
      color #FFFADC
      font-size .2rem
      border #D8BFA1 solid .06rem
      border-radius .06rem
      background-image linear-gradient(-15deg,#8E522EAA,#251D10,#251D10,#8E522EAA)
      .vip-close-btn
        width .44rem
        position absolute
        right -.21rem;
        top -.2rem;
      &.theme1
        border-color #D8BFA1
      .content-des
        padding-left .26rem
        margin-top .12rem
        line-height .32rem
.vip-wrapper
  width 7.58rem
  height 4.38rem
  padding-top .12rem
  position absolute
  z-index 1
  top 50%
  left 50%
  transform translateX(-50%) translateY(-50%)
  background-image url(../assets/acc-member-bg.png)
  background-size 100% 100%
  &.theme1
    background-image url(../assets/theme1/acc-bonus-background.png)
    .unlock-row
      width 80%
      color #e6c87d
      background-color transparent
      padding 0
      border-bottom 1px solid #333333
      &.unlocked
        &:after
          display none
  .vip-close-btn
    position absolute
    z-index 1
    top -.12rem
    right -.14rem
    width .48rem
    cursor pointer
  .vip-title
    font-size .46rem
    font-weight bold
    background-image -webkit-linear-gradient(#F8AE6D,#E29C60,#9f673e)
    -webkit-background-clip text;
    -webkit-text-fill-color transparent
    &.theme1
      background-image linear-gradient(#f7eecc,#f3e6c8,#f3e6c8,#c29b48,#ead3a0)
  .unlock-row
    width 95%
    margin auto
    padding 0 .57rem
    background-color #F8E8D8
    height .48rem
    border-bottom none
    justify-content space-between
    font-size .2rem
    align-items center
    color #717171
    &.unlocked
      &:after
        content ' '
        display block
        position absolute
        width 84%
        height .01rem
        background-color #cabeb0
        bottom 0
        left 50%
        transform translateX(-50%)
    &.rule
      border none
    .filed-title
      span
        color #d9823f
        &.theme1
          color #F9E3BF
    .unlock-rule
      font-size .26rem
      color #d9823f
      border-bottom 1px solid
      cursor pointer
      &.theme1
        color #E6C87D
    .receive-button
      width 1.08rem
      height .3rem
      margin-left .1rem
      background-size 100% 100%
      background-image url(../assets/receive-btn-bg.png)
      cursor pointer
      &.disabled
        filter grayscale(1)
        cursor default
      &.theme1
        background-image url(../assets/theme1/receive-btn-bg.png)
        width 1.08rem
        height .38rem
        &.disabled
          filter grayscale(1)
          cursor default
  .record-table
    width 80%
    margin .16rem auto 0 auto;
    border-radius .1rem
    overflow hidden
    .record-row
      width 100%
      display flex
      align-items center
      font-size .18rem
      text-align center
      background-color #EEC7B8
      & > div
        width 33.33%
        height .54rem
        display flex
        border-right 1px solid #9E715E
        border-bottom 1px solid #9E715E
        justify-content center
        align-items center
        line-height .2rem
        &:last-child
          border-right none !important
      &:last-child
        & > div
          border-bottom none !important
      &.head
        color white
        background-color #9F705E
        & > div
          height .41rem
          border-bottom none
    .record-l-scroll
      width 100%
      height 1.84rem
      overflow-x hidden
      overflow-y auto
      &::-webkit-scrollbar
        width 1px
      &::-webkit-scrollbar-thumb
        background #ffffff55
    &.theme1
      .record-row
        background-color #6A3C20AA
        color #E6C87D
        & > div
          border-right 1px solid #725546
          border-bottom 1px solid #725546
        &.head
          color #E6C87D
          font-size .2rem
          background-color #8D5F43AA


</style>
