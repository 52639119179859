<template>
  <div class="vip-w-container a2">
    <div class="vip-wrapper p-relative" :class="theme">
      <img v-if="theme == 'theme1'" class="vip-close-btn" @click="closeBtnClick"
        src="../assets/theme1/vip-close-btn.png" />
      <img v-else class="vip-close-btn" @click="closeBtnClick" src="../assets/vip-close-btn.png" />
      <div class="flex-center vip-title p-relative b-i" :class="theme">
        充值最高送{{ max }}%
      </div>
      <div class="link-btn-details title flex-center" @click="showDtailClick" :class="theme">
        查看详情
        <div class="d-arrow"></div>
      </div>

      <div class="process-container" :class="theme">
        <!-- <div class="no-unlock flex-center percent" :class="theme">
          待领取<span>{{ info.can_claim }}</span>
        </div> -->

        <div class="received-total-wrapper flex">
          <div class="flex act-500-des">
            <div class="flex-center act-amount-total-wrapper">
              <div>
                <div>
                  <span class="total-amount-value total-amount-area">总金额:{{ info.total_amount }}</span>
                </div>
                <div class="margin-top-10">
                  <span class="claimed-amount-area">已领取:{{ info.total_claimed }}</span>
                </div>
              </div>
              <div class="waiting-for-unlock">
                <div>
                  <span class="can-unlock-amount-area">待解锁:{{ info.can_unlock }}</span>
                </div>
                <div class="margin-top-10">
                  <span class="show-bonus-details-btn b500" @click="showVipDtailClick">
                    详情
                  </span>
                </div>
              </div>
            </div>
            <div class="unlocked-r-btn-container flex-center">
              <div class="can-claim-amount-area">待领取:{{ info.can_claim }}</div>
              <!-- <div class="un-r-btn-wrapper flex-center" @click="claim">
                <img class="r-b-btn received-btn" style="height:100%" src="../assets/theme1/receive-btn-bg.png" />
              </div> -->
              <div class="receive-button a2" @click="claim"
                :class="{ disabled: receivedDisabled, theme1: theme == 'theme1' }" style="cursor: pointer">
              </div>
            </div>
          </div>
        </div>

        <div class="flex p-container-wrapper">
          <div class="process-wrapper flex" :class="theme">
            <div class="processing-bar p-relative a1" :class="theme" :style="{ width: widthValue + '%' }">
              <div class="process-value">
                <span v-if="widthValue">
                  {{ widthValue + '%' }}
                </span>
              </div>
              <div class="processing-point flex-center">
                <div class="point-c"></div>
              </div>
            </div>
            <div class="space-w-unlock flex-center">
              未解锁
            </div>
          </div>
          <!-- <div class="total-amont" v-if="!theme" :class="theme">总金额:{{ info.total_amount }}</div> -->
        </div>
        <div class="flex bird-vip-w" :class="theme">
          <!--
          <div class="flex-center">
            <div class="receive-button a2" @click="claim"
              :class="{ disabled: receivedDisabled, theme1: theme == 'theme1' }" style="cursor: pointer">
            </div>
            <div class="link-btn-details flex-center" @click="showVipDtailClick" :class="theme">
              查看详情
              <div class="d-arrow"></div>
            </div>
          </div>
          <div class="no-unlock flex-center" :class="theme">
            未解锁
          </div>
          -->
        </div>
      </div>

      <div class="flex-center b-wrapper" :class="theme">
        <div class="first-bonus-menu" :class="[`n${i + 1}`, `${1 * item.is_show ? 'show' : ''}`]" v-for="(item, i) of bonusItem">
        </div>
      </div>
      <a class="to-recharge-btn" :href="'javascript:void(0)'"
        :class="{ disabled: !now_recharge || now_recharge < 1 || now_recharge > 6 }" :style="{ marginLeft: `${rechargeBtnPoi}rem` }"
        @click="gotoRechargeLink">
        <span>本轮已完成</span>
      </a>

    </div>

    <!-- 等级未达到提示弹窗 -->
    <div class="level-not-allow-wrapper" :class="{show:showLevelNotAllow}">
      <div class="vip-wrapper p-relative flex-center" :class="theme">
        <img v-if="theme == 'theme1'" class="vip-close-btn" @click="closeLevelWrapperBtnClick"
          src="../assets/theme1/vip-close-btn.png" />
        <img v-else class="vip-close-btn" @click="closeLevelWrapperBtnClick" src="../assets/vip-close-btn.png" />

        <div class="content">当前VIP等级不足</div>
        <div class="content">需升级解锁更多奖励次数</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      theme: '',
      receivedDisabled: 1,
      now_recharge: 1,
      bonusItem: [],
      widthValue: 0,
      max: 0,
      username: 'test',
      info: {
        total_claimed: 0,
        total_amount: 0,
      },
      rechargeUrl: '',
      rechargeBtnPoi: .23,
      rechargeBtnInitPoi:.23,
      showLevelNotAllow:0,
    }
  },
  mounted() {
    this.theme = globalConfig.theme;
    this.rechargeUrl = globalConfig.gotoRechargeUrl;
    let data = localStorage.getItem('act_recharge_data');
    data = JSON.parse(data);
    this.info = data;
    this.bonusItem = data.rules;
    this.now_recharge = data.recharge_count;
    if(this.theme == 'theme1'){
      this.rechargeBtnInitPoi = .28
    }else if(!this.theme){
      this.rechargeBtnInitPoi = .23
    }

    if(this.now_recharge < 7){
      this.rechargeBtnPoi = ((this.now_recharge - 1) * 118 + this.rechargeBtnInitPoi * 100) / 100;
      if (this.rechargeBtnPoi < this.rechargeBtnInitPoi) this.rechargeBtnPoi = this.rechargeBtnInitPoi;
    }

    this.bonusItem.forEach((row) => {
      if (row.gift_ratio > this.max) {
        this.max = row.gift_ratio;
      }
    });
    if (data.can_claim >= 5) {
      this.receivedDisabled = 0;
    }
    
    // can_unlock
    //可能需要改为   this.widthValue = Math.floor(this.info.can_claim / this.info.total_amount * 100);
    this.widthValue = Math.floor(this.info.total_claimed / this.info.total_amount * 100);

  },
  methods: {
    gotoRechargeLink(){
      if (!this.now_recharge || this.now_recharge < 1) {
        //
      }else{
        let bonusIndex = this.now_recharge - 1;
        if(!(1*this.bonusItem[bonusIndex].is_show)){
          this.showLevelNotAllow = Math.random();
        }else{
          window.open(this.rechargeUrl)
        }
      }

    },
    showDtailClick() {
      window.globalConfig.showPanelByKey('act-details')
    },
    showVipDtailClick() {
      window.globalConfig.showPanelByKey('b-details')
    },
    closeBtnClick() {
      window.globalConfig.showPanelByKey(''); //recharge-act
      window.globalConfig.updateHomeData();
    },
    closeLevelWrapperBtnClick(){
      this.showLevelNotAllow = 0
    },
    claim() {
      if (!this.receivedDisabled) {
        axios.post(globalConfig.api_domain + '/act_recharge/claim?username=' + localStorage.getItem('username')).then(response => {
          localAlert(response.data.message);
          setTimeout(() => {
            window.globalConfig.showPanelByKey('')
            window.globalConfig.updateHomeData();
          }, 500)
        }).catch(error => {
          console.error("There was an error fetching the data", error);
        });
      } else {
        localAlert('未达到领取条件')
      }
    },
  }
}
</script>

<style lang="stylus" scoped>
.level-not-allow-wrapper
  width 100%
  height 100%
  position fixed
  z-index 2
  top 0
  left 0
  background-color rgba(0,0,0,.5)
  display none
  &.show
    display flex
  .vip-wrapper
    width 6.54rem
    height 2.9rem
    flex-direction column
    .content
      text-align center
      font-size .36rem
      font-weight bold
      -webkit-background-clip text
      -webkit-text-fill-color transparent
      background-image -webkit-linear-gradient(#f8ae6d, #e29c60, #9f673e)
      &:last-child
        font-size .28rem
    &.theme1
      .content
        background-image linear-gradient(#f7eecc, #f3e6c8, #f3e6c8, #ffdf9c, #ead3a0)
.act-500-des
  width: 100%;
  justify-content: space-between;
  font-size:.16rem;
  padding:0 .2rem;
  & > div
    width:59%;
.show-bonus-details-btn
  cursor:pointer;
.show-bonus-details-btn.b500
  text-decoration: underline;
.unlocked-r-btn-container
  border-left: 1px solid;
  width: 41% !important;
.act-amount-total-wrapper
  justify-content: space-between;
  padding-right: 0.4rem;
.un-r-btn-wrapper
  width:auto;
  height: 0.3rem;
  margin-left: 0.08rem;
  .received-btn
    cursor pointer
.waiting-for-unlock
  text-align: right;
.margin-top-10
  margin-top:.1rem;
.vip-w-container
  width 100%
  height 100%
  position fixed
  z-index 2
  top 0
  left 0
  background-color rgba(0,0,0,.4)
.vip-wrapper
  width 7.58rem
  height 4.38rem
  padding-top .25rem
  position absolute
  z-index 1
  top 50%
  left 50%
  transform translateX(-50%) translateY(-50%)
  background-image url(../assets/acc-member-bg.png)
  background-size 100% 100%
  &.theme1
    padding-top .12rem
    background-image url(../assets/theme1/acc-bonus-background.png)
  .link-btn-details.title
    position absolute
    right .4rem
    color #d9823f
    z-index 1
    top 1.96rem
    &.theme1
      color #DFB25F
      top 1.96rem
  .vip-close-btn
    position absolute
    z-index 1
    top -.12rem
    right -.14rem
    width .48rem
    cursor pointer
  .vip-title
    font-size .46rem
    font-weight bold
    background-image -webkit-linear-gradient(#F8AE6D,#E29C60,#9f673e)
    -webkit-background-clip text;
    -webkit-text-fill-color transparent
    &.theme1
      background-image linear-gradient(#f7eecc,#f3e6c8,#f3e6c8,#c29b48,#ead3a0)

  .receive-button
    width 1.08rem
    height .3rem
    margin-left .1rem
    background-size 100% 100%
    background-image url(../assets/receive-btn-bg.png)
    &.disabled
      filter grayscale(1)
      cursor default
    &.theme1
      background-image url(../assets/theme1/receive-btn-bg.png)
      width 1.08rem
      height .38rem
      &.disabled
        filter grayscale(1)
        cursor default
  .link-btn-details
    cursor pointer
    font-size .14rem
    &.theme1
      color #DFB25F
      .d-arrow
        background-image url(../assets/theme1/detail-arrow.png)
    .d-arrow
      width:.1rem;
      height:.15rem;
      margin-left .04rem
      background-size 100% 100%
      background-image url(../assets/detail-arrow.png)
  .process-container
    width 96%
    margin-left .28rem
    padding-top .01rem
    &.theme1
      height 1.28rem
      .received-total-wrapper
        margin .14rem 0 .15rem 0
        color #E6C87D
    .received-total-wrapper
      width 95%
      height .68rem
      justify-content space-between
      align-items center
      color #d7853c
      font-size .18rem
      .receive-btn-bg
        height .34rem
        margin 0 .1rem
        cursor pointer
      .d-arrow
        width .11rem
        height .19rem
        margin-left .04rem
        background-size 100% 100%
        background-image url(../assets/theme1/detail-arrow.png)
    .p-container-wrapper
      width 100%
      margin-top .15rem
      .total-amont
        width 1.6rem
        color #D9823F
        font-size 24px
        line-height .18rem
        &.theme1
          color #dfb25f
    .process-wrapper
      width 95%
      height .18rem
      background #B98D72
      border-radius .1rem
      .space-w-unlock
        width 100%
        color white
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
      &.theme1
        width 93%
        margin-left .13rem
        background #180C06
        border-radius 0
      .processing-bar
        width 0%
        height 100%
        background linear-gradient(to right,#FF9303,#FF9100,#FBB200)
        border-radius .1rem
        text-align center
        flex-shrink 0
        .process-value
          color white
        &.theme1
          border-radius 0
          background url(../assets/theme1/process-b-img.png)
          background-size 100% 100%
          flex-shrink 0
          color white
          .process-value
            width 100%
            overflow hidden
            text-overflow ellipsis
            white-space nowrap
          .processing-point
            background url(../assets/theme1/processing-point.png)
            background-size 100% 100%
            right -.14rem
            .point-c
              background: linear-gradient(45deg, #efcc5e, #efd56a, #fff);
        .processing-point
          right 0
          width .3rem
          height .3rem
          border-radius 50%
          background #FF8D02
          top -.06rem
          right -.14rem
          position absolute
          box-shadow 0 0 .1rem .01rem #DA833C
          .point-c
            width 58%
            height 58%
            background white
            border-radius 50%
    .bird-vip-w
      width 78%
      justify-content space-between
      padding-top .14rem
      color #D7853C
      &.theme1
        color #E6C87D
        display none
    .no-unlock
      color white
      background-color #FFB400
      width 1.2rem
      height .27rem
      border-radius .2rem
      position relative
      font-size .14rem
      &.theme1
        display:none
        background-color #E0B161
        &:after
          width 0
          height 0
          bottom .27rem
          left 50%
          transform translateX(-50%)
          border-bottom .14rem solid #d7853c
          border-right .08rem solid transparent
          border-left .08rem solid transparent
          border-top none
          border-bottom-color #E0B161
        &.percent
          &:after
            width 0
            height 0
            bottom -.14rem
            left 50%
            transform translateX(-50%)
            border-top .14rem solid #d7853c
            border-right .08rem solid transparent
            border-left .08rem solid transparent
            border-bottom none
            border-top-color #E0B161

      &:after
        content ' '
        display block
        position absolute
        left .31rem
        bottom auto
        top -.1rem
        width .32rem
        height .1rem
        left 50%
        transform translateX(-50%)
        background-image url(../assets/tips-tail-ico1.png)
        background-size 100% 100%
      &.percent
        width .88rem
        margin .16rem 0 .15rem 0
        &:after
          bottom -.1rem
          top auto
          background-image url(../assets/tips-tail-ico.png)
  .to-recharge-btn
    background-image url(../assets/go-recharge-btn.png)
    background-size 100% 100%
    width 1.22rem
    height .38rem
    margin-left .3rem
    cursor pointer
    display block
    span
      display none
    &.disabled
      background-image linear-gradient(#808080, #808080) !important
      display flex
      justify-content center
      align-items center
      color #e1e1e1
      border-radius .1rem
      font-size .14rem
      text-decoration none
      cursor default
      span
        display inline
  &.theme1
    .to-recharge-btn
      background-image url(../assets/theme1/goto-recharge-btn.png)
      height .3rem
      width 1.08rem
  .b-wrapper
    padding .2rem 0 .06rem 0
    &.theme1
      padding .2rem 0 .14rem 0
      .first-bonus-menu
        &.n1
          background-image url(../assets/theme1/re-dec-ico-a1.png)
        &.n2
          background-image url(../assets/theme1/re-dec-ico-a2.png)
        &.n3
          background-image url(../assets/theme1/re-dec-ico-a3.png)
        &.n4
          background-image url(../assets/theme1/re-dec-ico-a4.png)
        &.n5
          background-image url(../assets/theme1/re-dec-ico-a5.png)
        &.n6
          background-image url(../assets/theme1/re-dec-ico-a6.png)
  .first-bonus-menu
    width 1.08rem
    height 1.5rem
    background-size 100% 100%
    margin-right .1rem
    filter grayscale(1)
    &.show
      filter grayscale(0)
    &:last-child
      margin-right 0
    &.n1
      background-image url(../assets/re-dec-ico-a1.png)
    &.n2
      background-image url(../assets/re-dec-ico-a2.png)
    &.n3
      background-image url(../assets/re-dec-ico-a3.png)
    &.n4
      background-image url(../assets/re-dec-ico-a4.png)
    &.n5
      background-image url(../assets/re-dec-ico-a5.png)
    &.n6
      background-image url(../assets/re-dec-ico-a6.png)
</style>
