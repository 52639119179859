<template>
  <div class="banner-container p-relative">
    <img class="banner-back theme1" src="../assets/theme1/banner.jpg" v-if="theme == 'theme1'" alt="">
    <img class="banner-back" src="../assets/banner.png" v-else alt="">

    <!-- <img src="../assets/jr-title-i.png" class="jr-title-ico" v-if="theme != 'theme1'" />
    <img src="../assets/jr-title-i1.png" class="jr-title-i1" v-if="theme != 'theme1'" /> -->

    <div class="acc-inputer-out flex p-relative" :class="theme">
      <div class="decorate-pay-warpper flex" :class="theme">
        <div v-for="item of walletLinks">
          <a target="_blank" :href="item.url">
            <img class="decorate-p-icon" src="../assets/theme1/main-decor-cgpay.png" v-if="item.wallet == 'cgpay'" />
            <img class="decorate-p-icon" src="../assets/theme1/main-decor-goubao.png" v-if="item.wallet == 'goubao'" />
            <img class="decorate-p-icon" src="../assets/theme1/main-decor-qianneng.png"
              v-if="item.wallet == 'qianneng'" />
            <img class="decorate-p-icon" src="../assets/theme1/main-decor-usdt.png" v-if="item.wallet == 'usdt'" />
            <img class="decorate-p-icon" src="../assets/theme1/main-decor-topay.png" v-if="item.wallet == 'topay'" />
            <img class="decorate-p-icon" src="../assets/theme1/main-decor-nopay.png" v-if="item.wallet == 'nopay'" />
            <span>{{ item.name }}</span>
          </a>
        </div>
      </div>
      <div class="flex-center tool-btn-out p-relative" :class="theme">
        <div class="btn q" @click="routerGo('vip')">
          <img v-if="theme == 'theme1'" src="../assets/theme1/tool-btn-ico-query.png" />
        </div>
      </div>
      <div class="flex-center tool-btn-out p-relative" :class="theme">
        <div class="btn r" @click="routerGo('recharge-act')">
          <img v-if="theme == 'theme1'" src="../assets/theme1/tool-btn-ico-gift.png" />
        </div>
        <div class="btn a" @click="routerGo('fvip')">
          <img v-if="theme == 'theme1'" src="../assets/theme1/tool-btn-ico-detail.png" />
        </div>
      </div>
    </div>

    <Vip v-if="queryType == 'vip'" />
    <RechargeAct v-if="queryType == 'act-details'" />
    <BonusItem v-if="queryType == 'recharge-act'" />
    <Tutorial v-if="queryType == 'tutorial'" />
    <FinancialVIP v-if="queryType == 'fvip'" />
    <BonusDetails v-if="queryType == 'b-details'" />
    <vipDetails v-if="queryType == 'vip-details'" />

    <div class="flex-center copy-right" v-if="theme == ''">
      COPYRIGHT&nbsp;©&nbsp;<span>金沙娱乐</span>&nbsp;版权所有人&nbsp;金沙娱乐
    </div>
    <div class="flex-center copy-right" v-if="theme == 'theme1'">
      COPYRIGHT&nbsp;©&nbsp;<span>金沙娱乐场</span>&nbsp;版权所有人&nbsp;金沙娱乐场
    </div>

  </div>
  <div class="loader" id="loading"></div>
</template>

<script>
import Vip from '../components/vip.vue'
import RechargeAct from '../components/rechargeAct.vue'
import BonusItem from '../components/bonusItem.vue'
import Tutorial from '../components/tutorial.vue'
import FinancialVIP from '../components/financialVIP.vue'
import BonusDetails from '../components/bonusDetails.vue'
import vipDetails from '../components/vipDetails.vue'
import axios from 'axios';

export default {
  name: 'HomeView',
  data() {
    return {
      queryType: '',
      theme: '',
      walletLinks: [],
    }
  },
  beforeRouteEnter(to, from, next) {
    next(_this => {
    //   _this.queryHandle(to);
      _this.theme = globalConfig.theme;
      _this.walletLinks = window.globalConfig.mainWalletLinks;
      window.globalConfig.showPanelByKey = _this.queryHandle;
    })
  },
  methods: {
    queryHandle(qtype) {
      this.queryType = qtype;
    },
    routerGo(qType) {
      const loading = document.getElementById('loading');
      // 添加请求拦截器
      axios.interceptors.request.use(function (config) {
        // 请求开始时，显示loading动画
        loading.style.display = 'block';
        return config;
      }, function (error) {
        // 如果请求出错，隐藏loading动画并返回错误
        loading.style.display = 'none';
        return Promise.reject(error);
      });

      // 添加响应拦截器
      axios.interceptors.response.use(function (response) {
        // 请求结束后，隐藏loading动画
        loading.style.display = 'none';
        return response;
      }, function (error) {
        // 如果响应出错，隐藏loading动画并返回错误
        loading.style.display = 'none';
        return Promise.reject(error);
      });

      if (qType === 'recharge-act') {
        if (!localStorage.getItem('username')) {
          localAlert("请先登录！");
          return false;
        }
        // 获取loading元素
        axios.get(globalConfig.api_domain + '/act_recharge?username=' + localStorage.getItem('username')).then(response => {
          if (parseInt(response.data.code) === 1) {
            localAlert(response.data.message);
          } else {
            localStorage.setItem('act_recharge_data', JSON.stringify(response.data.data));
            this.queryHandle(qType);
          }
        })
        .catch(error => {
          console.error("There was an error fetching the data", error);
        });
      } else if (qType === 'vip') {
        if (!localStorage.getItem('username')) {
          localAlert("请先登录！");
          return false;
        }

        axios.get(globalConfig.api_domain + '/vip?username=' + localStorage.getItem('username')).then(response => {
          if (parseInt(response.data.code) === 1) {
            localAlert(response.data.message);
          } else {
            localStorage.setItem('act_vip_data', JSON.stringify(response.data.data));
            this.queryHandle(qType);
          }
        })
        .catch(error => {
          console.error("There was an error fetching the data", error);
        });
      } else {
        this.queryHandle(qType);
      }
    }
  },
  components: {
    Vip,
    RechargeAct,
    BonusItem,
    Tutorial,
    FinancialVIP,
    BonusDetails,
    vipDetails,
  }
}
</script>

<style scoped lang="stylus">
  .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #df7123; /* Blue */
    border-radius: 50%;
    width:98px;
    height:98px;
    animation: spin 2s linear infinite;
    position: fixed;
    top: 47%;
    left: 47%;
    transform: translate(-50%, -50%);
    display: none;
    z-index:1100;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.banner-container{
  width:100%;
  height:auto;
  overflow:hidden;
}
.banner-container .banner-back{
  width:100%;
  height:auto;
  overflow:hidden;
  display:block;
}
.banner-container .banner-back.theme1{
  max-height:none;
}
.jr-title-ico{
  width:10.08rem;
  height:auto;
  position:absolute;
  left:50%;
  transform:translateX(-50%);
  top:.1rem;
}
.jr-title-i1{
  width: 5.24rem;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 4.4rem;
}
.banner-container .copy-right{
  width:100%;
  height:40px;
  background:#000000;
  font-size:12px;
  color:#817F80;
}
.banner-container .copy-right span{
  color:#E2CB7B;
}
.acc-inputer-out{
  position:absolute;
  left:50%;
  transform:translateX(-50%);
  top:5.8rem;
  z-index:1;
  width:5.04rem;
  height:1.44rem;
  background-image:url(../assets/acc-inputer-out.png);
  background-size:100% 100%;
  flex-direction: column;
  justify-content:space-between;
  align-items: center;
  padding:.23rem 0;
  &.theme1{
    background-image:url(../assets/theme1/acc-inputer-out.png);
    top:6.15rem;
  }
  .decorate-pay-warpper{
    position:absolute;
    top:-2.9rem;
    width:100vw;
    justify-content:center;
    align-items: center;
    height:1rem;
    display:none;
    & > div {
      position:absolute;
      &:last-child{
        margin-right:0;
      }
      &:nth-child(1){
        left:1.71rem;
        top:-.84rem;
      }
      &:nth-child(2){
        left:3.2rem;
        top:.8rem;
      }
      &:nth-child(3){
        left:1.71rem;
        top:2.15rem;
      }
      &:nth-child(4){
        right:1.71rem;
        top:2.15rem;
      }
      &:nth-child(5){
        right:3.2rem;
        top:.8rem;
      }
      &:nth-child(6){
        right:1.71rem;
        top:-.84rem;
      }
    }
    .decorate-p-icon{
      width:.54rem;
    }
    &.theme1{
      display:flex;
      background:none;
      top:-1.06rem;
      & > div{
        margin-right:.38rem;
        position:static;
        a{
          color:#FFFFE2;
          & > span{
            background-color:#120E0A;
          }
        }
      }
    }
    & > div{
      a{
        position:relative;
        & > span{
          display: block;
          position: absolute;
          bottom: -.03rem;
          left: 50%;
          transform: translateX(-50%);
          color: #f2edcb;
          width:.68rem;
          line-height:.23rem;
          background-color:rgba(181,54,54,.7);
          border-radius:.12rem;
          overflow: hidden;
          text-align: center;
          font-size:.12rem;
        }
      }
    }
  }
}
.btn{
  cursor: pointer;
}
.tool-btn-out .btn{
  width:1.6rem;
  height:.5rem;
  color:white;
  background-size:100% 100%;
  display:flex;
  justify-content: center;
  align-items: flex-start;
  line-height: .33rem;
  font-size:.2rem;
  font-weight:bold;
  color:white;
  padding-top:.08rem;
}
.tool-btn-out:last-child{
  margin-top:.08rem
}
.tool-btn-out.theme1:last-child{
  margin-top:0
}
.tool-btn-out.theme1{
  transform: translateX(18px);
}
.tool-btn-out.theme1 .btn{
  width:1.42rem;
  height:.38rem;
  color:white;
  background-size:100% 100%;
  display:flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size:.16rem;
  line-height: .21rem;
  padding-top:.08rem;
  position:relative;
}
.tool-btn-out .btn:nth-child(2){
  margin:0 0 0 .25rem;
}
.tool-btn-out.theme1 .btn.r{
  background-image:url(../assets/theme1/r-500-btn.png);
}
.tool-btn-out.theme1 .btn.q{
  background-image:url(../assets/theme1/r-query-btn.png);
  color:#050304;
}
.tool-btn-out.theme1 .btn.a{
  background-image:url(../assets/theme1/r-act-detail.png);
}
.tool-btn-out .btn.q{
  background-image:url(../assets/r-query-btn.png);
}
.tool-btn-out .btn.a{
  background-image:url(../assets/r-act-detail.png);
  margin-left:.56rem;
}
.tool-btn-out .btn.r{
  background-image:url(../assets/r-500-btn.png);
}

.tool-btn-out.theme1 .btn.q{
  color:#050304;
}
.tool-btn-out .btn img{
  height:.53rem;
  position:absolute;
  top: -.06rem;
  left: -.33rem;
}
.tool-btn-out{
  &.theme1{
    .btn{
      padding-top 0
      line-height .35rem
    }
  }
}

</style>
