<template>
  <div class="vip-w-container a3">
    <div class="vip-wrapper p-relative" :class="theme">
      <img v-if="theme == 'theme1'" class="vip-close-btn" @click="closeBtnClick"
        src="../assets/theme1/vip-close-btn.png" />
      <img v-else class="vip-close-btn" @click="closeBtnClick" src="../assets/vip-close-btn.png" />
      <div class="flex-center vip-title" :class="theme">
        金融VIP{{ grade }}
      </div>
      <div class="flex vip-youhui-title" :class="theme">
        VIP晋级奖励
        <div style="margin-left:.12rem">
          待领取:{{total}}
        </div>
        <div class="link-btn-details flex-center" @click="showDtailClick" v-if="!theme">
          <img src="../assets/vip-btn-details.png" style="height:.28rem" />
        </div>
        <div class="link-btn-details flex-center" @click="showDtailClick" v-if="theme == 'theme1'">
          <img src="../assets/theme1/vip-btn-details.png" style="height:.22rem" />
        </div>
      </div>
      <div class="process-container">

        <div class="process-tips-wrapper flex" v-if="!theme">
          <div class="tips-wrapper p-relative flex" :style="{ width: widthValue + '%', 'min-width': '.88rem' }">
            <div class="unlocked-value-percent p-relative flex-center">
              {{ widthValue + '%' }}
            </div>
          </div>
          <div class="process-empty-space flex">
            <div class="unlocked-value-percent p-relative flex-center" v-show="widthValue <= 96">
              未解锁
            </div>
          </div>
        </div>

        <div class="process-wrapper flex" :class="theme">
          <div class="processing-bar p-relative a2" :class="theme" :style="{ width: widthValue + '%' }">
            <span v-if="theme == 'theme1'">
              {{ widthValue > 2 ? widthValue + '%' : '' }}
            </span>
            <div class="processing-point flex-center">
              <div class="point-c"></div>
            </div>
          </div>
          <div v-if="theme == 'theme1'" style="width:100%;overflow:hidden;text-align: center;color:white">
            未解锁
          </div>
        </div>
        <div class="flex bird-vip-w" :class="theme">
          <div>
            <div class="vip-zq-title">金融VIP{{ grade }}</div>
            <!-- <div>每日04:00更新</div> -->
          </div>

          <div>
            <div class="vip-zq-title">
              金融VIP{{ parseInt(grade) + 1 }}
            </div>
            <!-- <div>每日04:00更新</div> -->
          </div>
        </div>
      </div>

      <div class="flex-center jiesuan-date" :class="theme">
        数据5分钟进行统计一次
      </div>
      <div class="jiesuan-date bet" :class="theme">
        <div>升级还需(充值):</div>
        <div class="amount" :class="theme">
          {{ recharge_left }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      theme: '',
      username: 'test',
      recharge_left: 0,
      grade: 0,
      total: 0,
      widthValue: 0,
    }
  },
  mounted() {
    this.theme = globalConfig.theme;
    let actVipData = localStorage.getItem('act_vip_data');
    if(actVipData){
      const res = JSON.parse(actVipData);
      this.getActVipDataShow(res.user_info)
    }else{
      this.getDataApi();
    }
  },
  methods: {
    getDataApi(){
      let userName = localStorage.getItem('username');
      if (!localStorage.getItem('username')) {
        localAlert("请先登录！");
        return false;
      }

      axios.get(globalConfig.api_domain + '/vip?username=' + userName).then(response => {
        if (parseInt(response.data.code) === 1) {
          localAlert(response.data.message);
        } else {
          localStorage.setItem('act_vip_data', JSON.stringify(response.data.data));
        }
      })
      .catch(error => {
        console.error("There was an error fetching the data", error);
      });
    },
    getActVipDataShow(data){
      if(!data) return;
      this.recharge_left = data.next_exp - data.exp;
      this.grade = data.grade;
      this.total = data.can_claim;
      this.widthValue = (data.exp / data.next_exp * 100).toFixed(1);
    },
    showDtailClick() {
      window.globalConfig.showPanelByKey('vip-details')
    },
    closeBtnClick() {
      window.globalConfig.showPanelByKey('')
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 加1因为月份从0开始
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    claimApi() {
      axios.post(globalConfig.api_domain + '/vip/claim?username=' + localStorage.getItem('username'))
        .then(response => {
          localAlert(response.data.message);
        })
        .catch(error => {
          console.error("There was an error fetching the data", error);
        });
    },
  }
}
</script>

<style lang="stylus" scoped>
.vip-w-container
  width 100%
  height 100%
  position fixed
  z-index 2
  top 0
  left 0
  background-color rgba(0,0,0,.4)
.vip-wrapper
  width 7.58rem
  height 3.88rem
  padding-top .12rem
  position absolute
  z-index 1
  top 50%
  left 50%
  transform translateX(-50%) translateY(-50%)
  background-image url(../assets/acc-member-bg.png)
  background-size 100% 100%
  &.theme1
    background-image url(../assets/theme1/acc-bonus-background.png)
    .vip-close-btn
      top -.07rem
      right -.05rem
  .vip-close-btn
    position absolute
    z-index 1
    top -.12rem
    right -.14rem
    width .48rem
    cursor pointer
  .vip-title
    font-size .46rem
    font-weight bold
    background-image -webkit-linear-gradient(#F8AE6D,#E29C60,#9f673e)
    -webkit-background-clip text;
    -webkit-text-fill-color transparent
    &.theme1
      background-image linear-gradient(#f7eecc,#f3e6c8,#f3e6c8,#c29b48,#ead3a0)
  .receive-button
    width 1.08rem
    height .3rem
    margin-left .1rem
    background-size 100% 100%
    background-image url(../assets/receive-btn-bg.png)
    &.theme1
      background-image url(../assets/theme1/receive-btn-bg.png)
      width 1.08rem
      height .38rem
  .link-btn-details
    cursor pointer
    margin-left .1rem
    font-size .2rem
  .vip-youhui-title
    width 3.6rem
    margin .14rem 0 0 .16rem
    padding .07rem 0 .07rem .18rem
    color #DB8031
    font-size .18rem
    line-height .3rem
    background-color #F8E8D8
    &.theme1
      width 90%
      padding 0
      margin .3rem auto
      color #E0B163
      align-items center
      background-color transparent
  .process-container
    width 90%
    margin auto
    .process-tips-wrapper
      width 100%
      height .68rem
      margin auto
      .tips-wrapper
        justify-content center
        align-items flex-end
        height auto
        overflow hidden
        padding-bottom .16rem
        flex-shrink 0
      .unlocked-value-percent
        width .88rem
        height .29rem
        font-weight bold
        border-radius .14rem
        background-color #FFB400
        color white
        flex-shrink 0
        &:after
          width .32rem
          height .1rem
          display block
          content ' '
          background url(../assets/tips-tail-ico.png)
          background-size 100% 100%
          position absolute
          left .31rem
          bottom -.1rem
      .process-empty-space
        width 100%
        height auto
        padding-bottom .16rem
        align-items flex-end
        justify-content center
    .process-wrapper
      width 95%
      height .18rem
      margin auto
      background #B98D72
      border-radius .1rem
      &.theme1
        background #180C06
        border-radius 0
      .processing-bar
        width 20%
        height 100%
        background linear-gradient(to right,#FF9303,#FF9100,#FBB200)
        border-radius .1rem 0 0 .1rem
        flex-shrink 0
        &.theme1
          background url(../assets/theme1/process-b-img.png)
          background-size cover
          border-radius 0
          text-align center
          color white
          .processing-point
            background url(../assets/theme1/processing-point.png)
            background-size 100% 100%
            right -.16rem
            .point-c
              background linear-gradient(45deg,#EFCC5E,#EFD56A,#FFFFFF)
        .processing-point
          width .3rem
          height .3rem
          border-radius 50%
          background #FF8D02
          top -.06rem
          right -.24rem
          position absolute
          box-shadow 0 0 .1rem .01rem #FE8E04
          .point-c
            width 58%
            height 58%
            background white
            border-radius 50%
    .bird-vip-w
      justify-content space-between
      padding-top .16rem
      color #A58A6F
      &.theme1
        color #E6C87D
        .vip-zq-title
          color #E6C87D
      .vip-zq-title
        font-size .18rem
        color #DF8C46
  .jiesuan-date
    width 100%
    color #323234
    font-size .18rem
    margin .14rem auto 0 auto
    color #684934
    &.theme1
      width 57%
      background black
      color #55E376
      padding .1rem 0
      &.bet
        width 100%
        margin-top .22rem
        height auto
        background none
        line-height inherit
        font-size .18rem
        background transparent
        color #E6C87D
    &.bet
      margin-top 0
      width 3.18rem
      margin .11rem auto 0 auto
      display flex
      justify-content center
      background #F1D7BE
      border-radius .24rem
      line-height .44rem
      font-size .22rem
    .amount
      color #684934
      &.theme1
        color #E6C87D
</style>
