<template>
  <nav class="flex-center" :class="theme">
    <img class="logo-ico" @click="navLogoClick" v-if="theme == 'theme1'" src="./assets/theme1/logo.png" alt=""
      style="margin-right:1rem;">
    <img class="logo-ico" @click="navLogoClick" v-else src="./assets/logo.png" alt="" style="margin-right:1rem;">

    <div class="nav-wrapper flex-center" :class="{ 'linear-grad-word': theme == 'theme1' }">
      <div class="flex-center" @click="navClick(0)">
        <img v-if="theme == 'theme1'" src="./assets/theme1/nav-ico-home.png" />
        <img v-else src="./assets/nav-ico-home.png" />
        返回官网
      </div>
      <div class="flex-center" @click="navClick(1)">
        <img v-if="theme == 'theme1'" src="./assets/theme1/nav-ico-wallet.png" />
        <img v-else src="./assets/nav-ico-wallet.png" />
        钱包教程中心
      </div>
      <div class="flex-center" @click="navClick(2)">
        <img v-if="theme == 'theme1'" src="./assets/theme1/nav-ico-app.png" />
        <img v-else src="./assets/nav-ico-app.png" />
        手机APP
      </div>
      <div class="flex-center" @click="navClick(3)">
        <img v-if="theme == 'theme1'" src="./assets/theme1/nav-ico-customer.png" />
        <img v-else src="./assets/nav-ico-customer.png" />
        在线客服
      </div>
    </div>

    <div class="flex" style="margin-left:.4rem;">
      <div class="welcome-sign-in flex-v-center" :class="theme">
        <img class="user-icon-img" v-if="theme == 'theme1'" src="./assets/theme1/sign-user-icon.png" />
        <img class="user-icon-img" v-if="!theme" src="./assets/sign-user-icon.png" />
        <div style="margin:0 5px">欢迎</div>
        <span class="login">登录</span>
      </div>

      <div class="welcome-sign-out flex-v-center" :class="theme">
        <img class="user-icon-img" v-if="theme == 'theme1'" src="./assets/theme1/sign-user-icon.png" />
        <img class="user-icon-img" v-if="!theme" src="./assets/sign-user-icon.png" />
        <div style="margin:0 5px">欢迎</div>
        <span class="login-color username" style="color: goldenrod;font-size: .15rem;line-height: .3rem"></span>
        <span class="btn-logout">[注销]</span>
      </div>

    </div>
    <div id="mask" style="display: none;">
      <div class="mask">
        <div class="verify">
          <div class="close"><span class="f-left"></span>×</div>
          <div class="login-main">
            <div class="top" data-title="账号登录"></div>
            <div class="hint">
              <b class="col-fff text-c">i</b>
              您输入的会员账号或BB余额不正确, 请重新输入
            </div>
            <form id="checkLogin">
              <input name="username" class="red-border" type="text" autocomplete="off" placeholder="填写会员账号">
              <label for="jizhuwo"
                style="display: flex;height: 28px;justify-content: space-between;line-height: 12px;margin-top: 3px;font-size: 16px;">
                <input class="col-fff" id="jizhuwo" type="checkbox"
                  style="/* display: inline-block; *//* height: 11px; *//* line-height: 20px; *//* padding: 5px; */"
                  checked="checked">记住会员账号
              </label>
              <input class="col-fff" id="verification" type="submit" value="立即验证">
            </form>
          </div>
          <div class="footer bottom">
            <a target="_blank" :href="signUpUrl">新会员注册&gt;&gt;</a>
            <span>|</span>
            <a class="CustomerServiceLineUrl" target="_blank"
              :href="fedbackUrl">意见反馈</a>
          </div>
        </div>
      </div>
    </div>

    <div class="amount-t" :class="{ 'linear-grad-word': theme == 'theme1' }">
      <div class="flex" :style="{ display: totalAmount === -1 ? 'none' : 'block' }">
        <div>总金额:{{ totalAmount }}</div>
      </div>
      <div class="flex" :style="{ display: claimed === -1 ? 'none' : 'block' }">
        <div>已领取:{{ claimed }}</div>
      </div>
      <div class="flex test1234" :style="{ display: unlocked === -1 ? 'none' : 'block' }">
        <div>待领取:{{ unlocked }}</div>
      </div>
    </div>
  </nav>

  <router-view />

  <div class="message-alert-wrapper flex-center" v-show="isShowAlert">
    <div class="m-a-content flex">
      <div class="title">提示</div>
      <div class="word-content-wrapper flex-v-center">
        {{ localAlertMsg }}
      </div>
      <div class="alert-btn" @click="alertConfirmClick">确定</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  data() {
    return {
      isShowAlert: 0,
      localAlertMsg: '',
      signUpUrl:'',
      fedbackUrl:'',
    }
  },
  mounted() {
    setTimeout(() => {
      this.lazyInit();
    },400);
  },
  methods: {
    alertConfirmClick() {
      this.isShowAlert = 0;
    },
    navLogoClick() {
      window.open(globalConfig.navLinks.logoNavUrl)
    },
    lazyInit(){
      window.localAlert = (msg) => {
        this.isShowAlert = Math.random();
        this.localAlertMsg = msg;
      }
      this.signUpUrl = window.globalConfig.signupUrl;
      this.fedbackUrl = window.globalConfig.fedbackUrl;
    }
  }
})
</script>

<script setup>
import { onMounted, ref } from 'vue';
import $ from 'jquery';
import axios from 'axios';
const theme = ref('');
const totalAmount = ref(-1);
const claimed = ref(-1);
const unlocked = ref(-1);

function layout() {
  let root = document.querySelector('html');
  let maxFontSize = global.innerWidth * 100 / 1470
  if(theme.value == 'theme1'){
    root.style.fontSize = maxFontSize + 'px';
  }else{
    root.style.fontSize = (maxFontSize > 114 ? 114:maxFontSize) + 'px';
  }
  
}
window.addEventListener('resize', () => {
  layout();
});
onMounted(() => {
  layout();
  theme.value = globalConfig.theme;
  if (localStorage.getItem('username')) {
    setTimeout(function () {
      switchLoginStatus(localStorage.getItem('username'));
    }, 50);
  }

  window.globalConfig.updateHomeData = function(){
    const loading = document.getElementById('loading');
    let userName = localStorage.getItem('username');

    if(userName){
      loading.style.display = 'block';
      checkLoginDataApi(userName, function (flag) {
        loading.style.display = 'none';
      })
    }
  };
  setTimeout(() => {
    window.globalConfig.updateHomeData();
  })

});

function navClick(index) {
  if (index == 0) {
    window.open(globalConfig.navLinks.official);
  } else if (index == 1) {
    window.globalConfig.showPanelByKey('tutorial')
  } else if (index == 2) {
    window.open(globalConfig.navLinks.appUrl);
  } else if (index == 3) {
    window.open(globalConfig.navLinks.customer);
  }
}
var c = axios.create({
  // baseURL: '', // api 的 base_url
  timeout: 30000, // request timeout
  async: false
});
function ApicheckLogin(data) {
  return c({
    url: globalConfig.api_domain + '/act_recharge/login',
    method: 'post',
    data
  })
}
function checkLoginDataApi(username, action) {
  return ApicheckLogin({ 'username': username }).then(function (response) {
    if (parseInt(response.data.code) === 1) {
      localAlert(response.data.message);
      action(false);
    } else {
      localStorage.setItem('username', username);
      localStorage.setItem('loginded-callback-data', JSON.stringify(response.data.data));

      totalAmount.value = response.data.data.total_amount; // 默认值为 500.22
      claimed.value = response.data.data.total_claimed; // 默认值为 500.22
      unlocked.value = response.data.data.can_claim; // 默认值为 500.22
      action(true)
    }
  }).catch(function (error) {
    console.error("Error caught: ", error);
    action(false)
  })
}

function verifyLogin(action) {
  // $('.test').text('test1')
  if (localStorage.getItem('member')) {
    member = JSON.parse(localStorage.getItem('member'));
    if (member && Object.keys(member).length && member.name) {
      localStorage.setItem('member', JSON.stringify(member));
      return true
    }
  }
  // $('.test').text('test2')
  if (action) {
    action()
  }
  // $('.test').text('test3')
  return false
}

function clickLogout() {
  localStorage.removeItem('member');
  localStorage.removeItem('username');
  switchLoginStatus();
}
function switchLoginStatus(memberName) {
  $('.welcome-sign-in').hide();
  $('.username').hide();

  if (memberName) {
    $('.username').html(' ' + memberName).show();
    $('.welcome-sign-out').css('display', 'flex');
    let res = localStorage.getItem('loginded-callback-data');
    if(res){
      let data = JSON.parse(res);
      totalAmount.value = data.total_amount; // 默认值为 500.22
      claimed.value = data.total_claimed; // 默认值为 500.22
      unlocked.value = data.can_claim; // 默认值为 500.22
    }
  } else {
    $('.welcome-sign-in').show();
    $('.welcome-sign-out').hide();

    totalAmount.value = -1;
    claimed.value = -1;
    unlocked.value = -1;
  }
}
function loginMashShow(flag) {
  if (flag) {
    getRememberName();
    $('#mask').fadeIn()
  } else {
    $('#mask').fadeOut()
  }
}
function rememberName(username) {
  var rememberCheckbox = $('.red-border').val();
  if (rememberCheckbox) {
    localStorage.setItem('rememberName', username);
  }
}

function getRememberName() {
  var rememberName = localStorage.getItem("rememberName");
  if (rememberName) {
    $('input[name=username]').val(rememberName);
  }
}

$(document).ready(function () {
  $('.login').click(function () {
    loginMashShow(true);
  });
  $('.close').click(function () {
    loginMashShow(false);
  });
  $('.suspend_close').click(function () {
    $('#suspend').fadeOut()
  });

  $('.btn-logout').click(function () {
    clickLogout()
  });

  const loading = document.getElementById('loading');
  let loginApiLoading = 0;
  $('#checkLogin').submit(function (e) {
    e.preventDefault();
    if (loginApiLoading) {
      return;
    }

    var username = $('input[name=username]').val();

    if (username === '') {
      localAlert('请输入用户名', { time: 1000, icon: 5 });
      return false;
    }
    if (username.length < 4) {
      localAlert('用户名不能小于4位', { time: 1000, icon: 5 });
      return false;
    }
    username = username.toLowerCase();

    rememberName(username);

    loginApiLoading = 1;
    loading.style.display = 'block';
    checkLoginDataApi(username, function (flag) {
      loginApiLoading = 0;
      loading.style.display = 'none';
      if (!flag) {
        return false;
      }
      localAlert('登录成功');
      $('#mask').hide();
      switchLoginStatus(username);
    })
  })
});

</script>

<style lang="stylus">
html,body
  margin 0
  padding 0
body
  font-size .14rem
*
  box-sizing border-box

::-webkit-scrollbar
  width .02rem
::-webkit-scrollbar-button         // — 滚动条上的按钮 (上下箭头)
  display none
::-webkit-scrollbar-thumb          // — 滚动条上的滚动滑块
  background #fff
::-webkit-scrollbar-track          // — 滚动条轨道
  background transparent
::-webkit-scrollbar-track-piece    // — 滚动条没有滑块的轨道部分
  background transparent
::-webkit-scrollbar-corner         // — 当同时有垂直滚动条和水平滚动条时交汇的部分
  display none
::-webkit-resizer                  // — 某些元素的corner部分的部分样式(例:textarea的可拖动按钮)
  display none
.p-relative
  position relative
.flex
  display flex
.flex-center
  display flex
  justify-content center
  align-items center
.flex-v-center
  display flex
  align-items center
</style>

<style scoped lang="stylus">
.message-alert-wrapper
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  background-color rgba(0,0,0,.6)
  z-index 1200
  .m-a-content
    width 288px
    height auto
    background-color white
    border-radius .02rem
    color black
    font-size .12rem
    text-align center
    padding-bottom .1rem
    flex-direction column
    align-items flex-end
    .word-content-wrapper
      width 100%
      min-height .88rem
      max-height 4rem
      overflow-x hidden
      overflow-y auto
      padding 0 .28rem
      color #0E0E0E
    .title
      color:#333237;
      font-size:16px;
      line-height:34px;
      background: #c9c9c9;
      width: 100%;
      text-align: left;
      padding-left: .15rem;
      border-bottom:1px solid #EEEEEE;
      background-color:#F8F8F8;
    .alert-btn
      width 74px
      line-height 30px
      background #e6a23c
      color white
      cursor pointer
      border-radius .02rem
      user-select none
      margin-right 20px
nav
  width 100%
  background white
  &.theme1
    background-image url(./assets/theme1/nav-back.png)
    background-size 100% 100%
.logo-ico{
  width:1.91rem;
  height:.58rem;
  cursor:pointer;
}

.mask .verify .top::before {
  content: attr(data-title);
  width: 158px;
  text-align: center;
  background-color: #fff;
  color: #202125;
  font-size: 17px;
  position: absolute;
  left: 96px;
  top: -12px;
}
.mask .verify {
  width: 539px;
  height: 332px;
  background-color: #fff;
  border: 2px solid #d7d7d7;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 120px;
}
.mask {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mask .close {
  width: 50px;
  height: 23px;
  line-height: 23px;
  color: #b3b3b3;
  font-size: 24px;
  cursor: pointer;
  text-align: center;
  align-self: flex-end;
}
.mask .close span {
  width: 1px;
  height: 23px;
  background: linear-gradient(to bottom, #d7d7d7, #fff);
}
.welcome-sign-out{
   display:none
}
.welcome-sign-out .btn-logout{
   color:#000000
}
.welcome-sign-out.theme1 .btn-logout{
   color:white
}
.welcome-sign-in,.welcome-sign-out{
  font-size:14px;
}
.welcome-sign-in .btn-logout,.welcome-sign-out .btn-logout{
  cursor:pointer;
}
.welcome-sign-in.theme1,.welcome-sign-out.theme1{
  color:#FCFBFF;
}
.welcome-sign-in.theme1 span,.welcome-sign-out.theme1 span{
  color:#dfe586;
}
.welcome-sign-in .user-icon-img,.welcome-sign-out .user-icon-img{
  height:18px;
}
.welcome-sign-in .login{
   cursor:pointer;
}
.mask .verify .login-main {
  padding-top: 20px;
  width: 350px;
  /* height: 306px; */
  flex: 1;
}
.mask .verify .top {
  border-top: 1px solid #dadada;
  padding-bottom: 20px;
  position: relative;
  margin-bottom: 5px;
}
.mask .verify .hint {
  visibility: hidden;
  padding-left: 25px;
  color: #ea2a2a;
  font-size: 14px;
  line-height: 34px;
  position: relative;
}
.mask .verify .hint b {
  width: 17px;
  line-height: 17px;
  position: absolute;
  left: 0;
  top: 8px;
  border-radius: 50%;
  background-color: #eb2b2b;
  color: #fff;
  text-align: center;
}
.mask .verify input[type="text"] {
  width: 350px;
  height: 40px;
  border: 1px solid #dcdcdc;
  border-radius: 2px;
  margin-bottom: 20px;
  font-size: 16px;
  padding-left: 10px;
}
#verification {
  cursor: pointer;
  width: 362px;
  height: 40px;
  border: 0;
  font-size: 18px;
  border-radius: 2px;
  color: #fff;
  background: #E6A23C;
}
.mask .footer {
  align-self: flex-end;
  margin-right: 15px;
  margin-bottom: 3px;
}
.mask .verify .bottom a {
  background: transparent;
  border: 0;
  font-size: 12px;
  color: #9d9d9d;
  cursor: pointer;
}
.mask .verify .bottom span {
  margin-right: 7px;
  color: #e5e5e5;
}
.mask .verify .bottom a {
  background: transparent;
  border: 0;
  font-size: 12px;
  color: #9d9d9d;
  cursor: pointer;
}
.nav-wrapper{
  font-size:.18rem;
  font-weight:bold;
}
.nav-wrapper img{
  width:.3rem;
  margin-right:.04rem;
}
.linear-grad-word{
  background-image:linear-gradient(to right,#F1BE3F,#F2EE80,#F1BE3F,#F2EE80,#F1BE3F,#F2EE80);
  -webkit-background-clip:text;
  -webkit-text-fill-color:transparent;
}
.nav-wrapper .flex-center{
  margin-left:.2rem;
  cursor: pointer;
}
nav .sign-btn{
  width:.68rem;
  margin-left:.2rem;
  cursor:pointer;
}
.amount-t{
  margin-left:.2rem;
  font-size:.13rem;
}
</style>
